.form-floating {
    .ts-wrapper {
        padding-top: 1.625rem !important;
        padding-bottom: 0 !important;
        height: unset;
    }

    .ts-wrapper.focus {
        outline: 0;
        box-shadow: $input-focus-box-shadow;
    }

    .ts-control {
        padding-top: 3px;
        padding-bottom: 8px;
        min-height: 2rem;
        outline: none !important;
    }

    .focus .ts-control {
        box-shadow: none;
        border: none;
    }


    .items-placeholder::placeholder {
        color: transparent;
    }

    label {
        pointer-events: unset;
    }

    .ts-dropdown {
        .dropdown-input-wrap {
            margin: -1px -1px 0;
            width: calc(100% + 2px);
        }
    }

}

/*
body .form-floating {
    > .form-control,
    > .form-control-plaintext,
    > .form-select {
        ~ label {
            opacity: 1 !important;
            transform: $form-floating-label-transform;
        }
    }
}

.form-control.ql-container, .form-floating .form-control.ql-container {
    padding: 0;
    height: auto;
}

.form-floating .ql-toolbar.ql-snow {
    padding-top: 2rem;
}
*/

.tox.tox-tinymce {
    border: $input-border-width solid $input-border-color;
    padding: 0;

    @include border-radius($input-border-radius, 0);

    .tox-edit-area::before {
        border-radius: 0;
        border-width: $input-border-width;
        border-color: $input-focus-border-color;
    }
}


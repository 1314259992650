$dark: #030303 !default;
$success: #51a351 !default;
$danger: #bd362f !default;
$info: #2f96b4 !default;
$warning: #f89406 !default;

@import "~toastr/toastr.scss";

#toast-container > div {
    opacity: 1;
}

.toast {
    background-color: $dark;
}

.toast-success {
    background-color: $success;
}

.toast-error {
    background-color: $danger;
}

.toast-info {
    background-color: $info;
}

.toast-warning {
    background-color: $warning;
}

#toast-container .toast {
    transition: box-shadow 0.2s;
}

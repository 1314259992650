.dropdown-menu {
    --#{$prefix}slidein-margin: 10px;
    --#{$prefix}dropdown-spacer: 2px;
}

.dropdown-menu {
    .dropdown-item {
        transition: $transition-base;
    }
}

@each $color, $value in $theme-colors {
    @if not index(('primary', 'secondary'), $color) {
        .dropdown-menu {
            .dropdown-item-#{$color} {
                color: $value;
                background-color: #{shift-color($value, $bg-soft-scale)};

                .alert-link {
                    color: $value;
                }

                &:hover {
                    color: $value;
                    background-color: #{shift-color($value, $bg-soft-scale / 1.1)};
                }
            }
        }
    }
}

[data-theme="dark"] {
    // Soft variant
    @each $color, $value in $theme-dark-colors {
        @if not index(('primary', 'secondary'), $color) {
            .dropdown-menu {
                .dropdown-item-#{$color} {
                    color: $value;
                    background-color: #{shift-color($value, $bg-soft-scale)};

                    &:hover {
                        color: $value;
                        background-color: #{shift-color($value, $bg-soft-scale / 1.1)};
                    }
                }
            }
        }
    }
}

//
// Scroll shadow
//

.scroll-shadow {
    &::before {
        content: "";
        position: sticky;
        z-index: 0;
        top: 0;
        display: block;
        height: .1875rem;
        box-shadow: inset $box-shadow-sm;
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        display: block;
        width: 100%;
        height: .1875rem;
        background: var(--#{$prefix}white);
    }
}
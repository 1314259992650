[data-theme="dark"] {
    color-scheme: dark;
}

main#content {
    //background-color: $main-content-bg;

    &:after {
        content: unset;
    }
}

nav#mainNavbar, main#content, main#content footer {
    transition: $transition-base, background-color 0s;
}

[data-sidebar-behaviour="fixed"] main#content footer, [data-sidebar-behaviour="condensed"] main#content footer {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
}

@include media-breakpoint-up(lg) {
    [data-sidebar-behaviour="condensed"] main#content footer {
        margin-left: $navbar-vertical-condensed-width;
    }
    [data-sidebar-behaviour="fixed"] main#content footer {
        margin-left: $navbar-vertical-width;
    }
}

[data-sidebar-behaviour="fixed"] .sidebar-behaviour-toggle > * {
    transform: rotate(180deg);
}

[data-sidebar-behaviour="fixed"] {
    .d-nav-fixed-none {
        display: none !important;
    }
}

@include media-breakpoint-up(lg) {
    [data-sidebar-behaviour="fixed"] {
        .d-nav-fixed-none {
            display: none !important;
        }
    }

    [data-sidebar-behaviour="condensed"] {
        .d-nav-condensed-none {
            display: none !important;
        }
    }
}

@include media-breakpoint-down(lg) {
    .d-nav-fixed-none {
        display: none !important;
    }
}

@include media-breakpoint-up(lg) {
    [data-sidebar-behaviour="condensed"] .navbar-vertical .navbar-nav .nav-link {
        border-radius: 0;
        text-align: center;
    }
}

[data-sidebar-behaviour="fixed"] .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link {
    margin-right: 1rem;
}

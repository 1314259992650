.table-hover > tbody > tr:hover > * {
    transition: $transition-base;
}

.table-responsive > .table {
    margin-bottom: 0px;
}

.table {
    > thead {
        th {
            border-bottom-width: $table-border-width;
        }
    }
}

.table-responsive.table-responsive-flush {
    width: calc(100% + $border-width * 2);
    margin-left: -$border-width !important;
    margin-right: -$border-width !important;
    margin-top: -$border-width !important;
    margin-bottom: -$border-width !important;
}

.table.table-xs > :not(caption) > * > * {
    padding: .25rem 1.25rem;
    font-size: small;
}

.flatpickr-calendar.arrowBottom {
    margin-top: -0.5rem;
}

.flatpickr-calendar {
    padding: 0.5rem;
}

.flatpickr-innerContainer {
    justify-content: center;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
    //min-width: 210px;

    .flatpickr-am-pm {
        width: 20%;
    }
}


.flatpickr-time {
    gap: 0 10px;

    &:after {
        display: none;
    }

    & > * {
        float: none !important;
    }

    .numInputWrapper span {
        opacity: 0.25;
        padding: 0;
        border-width: 0;
        border-left-width: 1px;
        border-color: var(--bs-border-color);

        &:hover {
            opacity: 1;
        }
    }

    input.numInput {
        padding-right: 30px;
    }

    span.flatpickr-time-separator {
        width: auto;
    }

    .numInputWrapper, .flatpickr-time .flatpickr-am-pm {
        border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
        overflow: hidden;
        border-radius: 3px;

        -webkit-align-self: unset;
        -ms-flex-item-align: unset;
        align-self: unset;
    }

    .numInputWrapper span {
        width: 30px;
    }

    .numInputWrapper span:after {
        left: calc(50% - 4px);
        top: calc(50% - 2px);
    }

}



//
// Cursor
//

.cursor-pointer {
    cursor: pointer;
}

.cursor-none {
    cursor: none;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.cursor-text {
    cursor: text;
}

.cursor-default {
    cursor: default;
}

.cursor-help {
    cursor: help;
}

.cursor-move {
    cursor: move;
}

.cursor-copy {
    cursor: copy;
}

.cursor-wait {
    cursor: wait;
}
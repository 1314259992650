/*

Theme: Dashly
Product Page: https://themes.getbootstrap.com/product/dashly-admin-dashboard-template/
Author: Webinning
Author URI: https://webinning.co.uk
Support: support@dashly-theme.com

---

Copyright 2022 Webinning

*/

// Bootstrap functions
@import '~bootstrap/scss/functions';

// Custom variables
@import "variables";

// Theme variables
@import "../../src/scss/theme/variables";

// Theme configuration
@import "../../src/scss/theme/maps";

// Bootstrap configuration
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";

// Theme utilities
@import "../../src/scss/theme/utilities";

// Bootstrap utilities
@import "~bootstrap/scss/utilities";

// Theme layout & components
@import "../../src/scss/theme/root";

// Bootstrap layout & components
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/placeholders";

// Theme Helpers
@import "../../src/scss/theme/helpers";

// Bootstrap Helpers
@import "~bootstrap/scss/helpers";

// Bootstrap utilities API
@import "~bootstrap/scss/utilities/api";

// Dashly layout & components
@import "../../src/scss/theme/mixins";
@import "../../src/scss/theme/reboot";
@import "../../src/scss/theme/alert";
@import "../../src/scss/theme/avatar";
@import "../../src/scss/theme/badge";
@import "../../src/scss/theme/board";
@import "../../src/scss/theme/breadcrumb";
@import "../../src/scss/theme/button-group";
@import "../../src/scss/theme/buttons";
@import "../../src/scss/theme/card";
@import "../../src/scss/theme/chat";
@import "../../src/scss/theme/close";
@import "../../src/scss/theme/cursor";
@import "../../src/scss/theme/dropdown";
@import "../../src/scss/theme/forms";
@import "../../src/scss/theme/layout";
@import "../../src/scss/theme/legend-circle";
@import "../../src/scss/theme/list-group";
@import "../../src/scss/theme/modal";
@import "../../src/scss/theme/nav";
@import "../../src/scss/theme/navbar";
@import "../../src/scss/theme/offcanvas";
@import "../../src/scss/theme/progress";
@import "../../src/scss/theme/scroll-shadow";
@import "../../src/scss/theme/scrollbar";
@import "../../src/scss/theme/steps";
@import "../../src/scss/theme/tables";
@import "../../src/scss/theme/tooltip";

// Dashly only components
@import "../../src/scss/theme/vendors/chart";
@import "../../src/scss/theme/vendors/flag-icons";
@import "../../src/scss/theme/vendors/password";
@import "../../src/scss/theme/vendors/picmo";
@import "../../src/scss/theme/vendors/scrollspy";
@import "../../src/scss/theme/vendors/dragula";
@import "../../src/scss/theme/vendors/dropzone";
@import "../../src/scss/theme/vendors/flatpickr";
@import "fullcalendar";
@import "../../src/scss/theme/vendors/list";
@import "../../src/scss/theme/vendors/quill";
@import "../../src/scss/theme/vendors/tom-select";
@import "../../src/scss/theme/vendors/jsvectormap";
@import "../../src/scss/theme/vendors/highlightjs";

@import "animate.css";

// Custom style
@import "./tables";
@import "./tinymce";
@import "./layout";
@import "./toastr";
@import "./dropdowns";
@import "./floating_labels";
@import "./flatpickr";
@import "./style";




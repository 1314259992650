//
// Badge
//

// Badge circle
.badge-circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 0;
    min-width: unset;
    width: $badge-circle-size;
    height: $badge-circle-size;
}
//
// Alert
//

// scss-docs-start alert-modifiers
// Generate contextual modifier classes for colorizing the alert.
@each $state, $value in $theme-colors {
    $alert-background: shift-color($value, $alert-bg-scale);
    $alert-border: shift-color($value, $alert-border-scale);
    $alert-color: shift-color($value, $alert-color-scale);
  
    @if (contrast-ratio($alert-background, $alert-color) < $min-contrast-ratio) {
        $alert-color: mix($value, color-contrast($alert-background), abs($alert-color-scale));
    }
    .alert-#{$state} {
        @include alert-variant($alert-background, $alert-border, $alert-color);
    }
}

[data-theme="dark"] {
    @each $state, $value in $theme-dark-colors {
        $alert-background: shift-color($value, $alert-bg-scale);
        $alert-border: shift-color($value, $alert-border-scale);
        $alert-color: shift-color($value, $alert-color-scale);
    
        @if (contrast-ratio($alert-background, $alert-color) < $min-contrast-ratio) {
            $alert-color: mix($value, color-contrast($alert-background), abs($alert-color-scale));
        }
        .alert-#{$state} {
            @include alert-variant($alert-background, $alert-border, $alert-color);
        }
    }
}
// scss-docs-end alert-modifiers
/**
 * Copy and paste the variables that...
 *
 * Example for the most commom variables:
 *
 * $primary: #d138f2;
 * $secondary: #ff0000;
 * $font-family-base: 'Merriweather', serif;
 */

$border-radius: 3px;
$btn-border-radius: 3px;
$btn-border-radius-lg: 3px;
$btn-border-radius-sm: 3px;
$grid-gutter-width: 1rem;

$table-th-font-size: var(--bs-body-font-size);

$offcanvas-horizontal-width: 500px;

$input-font-size: var(--bs-body-font-size);

$form-floating-height: add(3.5rem, 4px);

$form-floating-label-opacity: 1;

$navbar-link-icon-width: 2rem;

$card-title-spacer-y: 0px;

$zindex-dropdown: 1075;

$input-padding-y: 0.53125rem;

$navbar-padding-x: 1.25rem;

$navbar-link-icon-margin-right: .5rem;

$btn-padding-x: 1rem;


$small-font-size: .875em !default;

$tooltip-max-width: 800px;

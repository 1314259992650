/**
 * Define you custom style here.
 * It will be merged with the default style.
 */

[data-fetch] {
    cursor: pointer;
}

.ts-control > input {
    min-width: 0;
}

.fa-fw {
    width: 1.5em;
}

form.quick-search-bar {
    max-width: 250px;
    transition: $transition-base
}

form.quick-search-bar:focus-within, form.quick-search-bar:focus {
    max-width: 450px;
}


.modal-dialog-scrollable .modal-body {
    scrollbar-width: thin;
}

.m-inset {
    width: calc(100% + $border-width * 2);
    margin-left: -$border-width !important;
    margin-right: -$border-width !important;
    margin-top: -$border-width !important;
    margin-bottom: -$border-width !important;
}

.m-inset-x {
    width: calc(100% + $border-width * 2);
    margin-left: -$border-width !important;
    margin-right: -$border-width !important;
}

.m-inset-y {
    margin-top: -$border-width !important;
    margin-bottom: -$border-width !important;
}

/*.dropdown-menu {
    margin-top: 0 !important;
}*/

/*
todo check this
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
*/

.modal-dialog.modal-fluid {
    max-width: none;
    margin-left: 20px;
    margin-right: 20px;
}

/*html {
    overflow-y: scroll;
}*/
